<template>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    "
  >
    <card-shipping
      v-for="item in list"
      :key="item.id"
      :shipping="item"
      @emitDelete="emitedHandleDelete"
      @emitEdit="emitedHandleEdit"
    />

    <div class="m-b-50">
      <div
        v-if="isLoading"
        v-loading="isLoading"
        class="df ai-c jc-c m-t-24"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)"
      />
      <iq-mobile-button
        v-if="isAllowLoadMore"
        :is-loading="isLoading"
        title="Показать еще"
        @onClick="$emit('handleGetMoreTimeslot')"
      />
    </div>
    <template v-if="list.length === 0 && !isLoading">
      <el-empty description="нет данных" />
    </template>
  </div>
</template>

<script>
import CardShipping from '@/views/manager/components/mobile/CardShipping'
import IqMobileButton from '@/views/ui/components/mobileButton/IqMobileButton'

export default {
  name: 'ManagerListShippingMobile',
  components: { IqMobileButton, CardShipping },
  props: {
    list: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    totalCount: { type: Number, default: 0 },
  },
  data() {
    return {}
  },
  computed: {
    isAllowLoadMore() {
      return this.list.length > 0 && this.totalCount > this.list.length
    },
  },
  methods: {
    emitedHandleEdit(item) {
      this.$emit('editRow', item)
    },
    emitedHandleDelete(item) {
      this.$emit('deleteRow', item)
    },
  },
}
</script>

<style lang="sass" scoped></style>
