var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        "flex-direction": "column",
      },
    },
    [
      _vm._l(_vm.list, function (item) {
        return _c("card-shipping", {
          key: item.id,
          attrs: { shipping: item },
          on: {
            emitDelete: _vm.emitedHandleDelete,
            emitEdit: _vm.emitedHandleEdit,
          },
        })
      }),
      _c(
        "div",
        { staticClass: "m-b-50" },
        [
          _vm.isLoading
            ? _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                staticClass: "df ai-c jc-c m-t-24",
                attrs: {
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "rgba(255, 255, 255, 0.8)",
                },
              })
            : _vm._e(),
          _vm.isAllowLoadMore
            ? _c("iq-mobile-button", {
                attrs: { "is-loading": _vm.isLoading, title: "Показать еще" },
                on: {
                  onClick: function ($event) {
                    return _vm.$emit("handleGetMoreTimeslot")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.list.length === 0 && !_vm.isLoading
        ? [_c("el-empty", { attrs: { description: "нет данных" } })]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }